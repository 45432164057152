import {createSlice} from "@reduxjs/toolkit";

const initialInvoiceState = {
  isLoading: false,
  notification: null,
  invoices: null
};

export default createSlice({
  name: "invoice",
  initialState: initialInvoiceState,
  reducers: {
    // Invoice Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Invoice Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Invoice Reducers
     */

    // fetchInvoices
    invoicesFetched: (state, action) => {
      state.isLoading = false;
      state.invoices = action.payload?.sort((a, b) => new Date(b.createdAt) -  new Date(a.createdAt));
    },    

  }
});