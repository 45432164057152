import {createSlice} from "@reduxjs/toolkit";

const initialOrdersState = {
  isLoading: false,
  notification: null,
  data: null
};

export default createSlice({
  name: "orders",
  initialState: initialOrdersState,
  reducers: {
    // Orders Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Orders Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Orders Reducers
     */

    // fetchOrders
    ordersFetched: (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.sort((a, b) => new Date(b.createdAt) -  new Date(a.createdAt));
    },    

  }
});