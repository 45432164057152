import authSlice from "app/redux/auth/authSlice";
import balancesSlice from "app/redux/balances/balancesSlice";
import dashboardSlice from "app/redux/dashboard/dashboardSlice";
import inventorySlice from "app/redux/inventory/inventorySlice";
import customersSlice from "app/redux/customers/customerSlice";
import ordersSlice from "app/redux/orders/ordersSlice";
import referralsSlice from "app/redux/referrals/referralsSlice";
import settingsSlice from "app/redux/settings/settingsSlice";
import transactionsSlice from "app/redux/transactions/transactionsSlice";
import {combineReducers} from "redux";
import invoiceSlice from "app/redux/invoice/invoiceSlice";


const appReducer = combineReducers({
  auth: authSlice.reducer,
  dashboard: dashboardSlice.reducer,
  transactions: transactionsSlice.reducer,
  inventory: inventorySlice.reducer,
  invoice: invoiceSlice.reducer,
  customers: customersSlice.reducer,
  balances: balancesSlice.reducer,
  orders: ordersSlice.reducer,
  referrals: referralsSlice.reducer,
  settings: settingsSlice.reducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'auth/loggedOut') {
    /* Clear All Data on logout */
    state = undefined;
  }
  if (action.type === 'auth/setMode') {
    /* Reset All Data except auth on mode change */
    if (state.auth?.mode !== action.payload){
      state = {auth: state.auth};
    }
  }
  if (action.type === 'settings/setBusiness') {
    /* Reset All Data except auth on mode change */
    if (state.settings?.business?.id !== action.payload?.id){
      state = {auth: state.auth, settings: state.settings};
    // console.log(action.type, action.payload, state.settings.business)
    }

  }

  

  
  return appReducer(state, action)
}


