import {createSlice} from "@reduxjs/toolkit";

const initialCustomerState = {
    isLoading: false,
    notification: null,
    customers: null
};

export default createSlice({
    name: "customers",
    initialState: initialCustomerState,
    reducers: {
        // Customer Response Notification
        setNotification: (state, action) => {
            state.isLoading = false;
            state.notification = action.payload.notification;
        },

        // Customer Loading
        isLoading: (state, _) => {
            state.isLoading = true;
            state.notification = null
        },

        /*****
         *  Customers Reducers
         */
        customersFetched: (state, action) => {
            state.isLoading = false;
            state.customers = action.payload?.map(item => ({...item}));
        },
    }
});